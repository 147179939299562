<template>
  <List
    :title="'Product'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="true"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    @optionSel="handleOption"
    @create="create = true"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages.="pages"
    @changePage="handlePageChange"
  />
  <see-modal
    v-if="see"
    :qr="entries.find((el) => el.id === selItem).barcode"
    :title="`Product Card`"
    :items="toSee"
    @close="see = false"
  />
  <edit-modal
    v-if="editItem"
    :title="`Edit product`"
    :items="toEdit"
    @close="editItem = false"
    @submitData="editProduct"
  />
  <Create
    v-if="create"
    :items="toCreate"
    :response="response"
    @data="handleCreateData"
    @close="create = false"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import api from "@/mixins/optionsApi";
import SeeModal from "@/components/Ui/CRUD/See.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
import Create from "@/components/Create/Index.vue";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Products",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      canCreate: [4, 5, 6, 7, 8, 9],
      headItems: [
        "Product",
        "Serial Nº",
        "Category",
        "Site",
        "Client",
        "Last Edit",
      ],
      bodyItems: [],
      editOptions: ["see", "edit"],
      search_key: "",
      hasOptions: true,
      see: false,
      editItem: false,
      create: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      tabFilters: [
        {
          name: "All Products",
          filter: "",
          isActive: true,
        },
      ],
      isActive: 0,
      response: [],
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
    SeeModal,
    EditModal,
    Create,
  },

  mixins: [timeSince, isMobile, api],

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.getData(val);
      },
    },

    filter() {
      this.getData(1);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
      this.getData(1);
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      return [
        {
          name: "categories",
          options: this.api("categories"),
        },
        {
          name: "sites",
          options: this.api("sites"),
        },
      ];
    },
    toCreate() {
      return [
        {
          type: "image",
          name: "image",
          label: "Image",
          required: false,
        },
        {
          type: "select",
          name: "category",
          label: "Category",
          options: this.api("categories"),
          required: true,
        },
        {
          type: "text",
          name: "name",
          label: "Name",
          required: true,
        },
        {
          type: "text",
          name: "serial_no",
          label: "Serial Nº",
          required: true,
        },
        {
          type: "text",
          name: "product_no",
          label: "Product Number",
          required: true,
        },
        {
          type: "text",
          name: "description",
          label: "Description",
          required: true,
        },
        {
          type: "text",
          name: "model",
          label: "Model",
          required: true,
        },
        {
          type: "text",
          name: "specifications",
          label: "Specifications",
          required: true,
        },
        {
          type: "file",
          name: "documents",
          label: "Documents",
          required: false,
        },
        {
          type: "boolean",
          name: "installed",
          label: "Installed",
          required: false,
          extra: [
            {
              type: "date",
              name: "warranty_start",
              label: "Warranty Start",
              required: false,
            },
            {
              type: "date",
              name: "warranty_end",
              label: "Warranty End",
              required: false,
            },
          ],
        },
      ];
    },
    toSee() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      if (Object.keys(item).length) {
        return [
          {
            title: "Header",
            image: item.product_images.length
              ? this.imgURL +
                item.product_images[item.product_images.length - 1].image
              : item.categories.length
              ? item.categories[0].images.length
                ? this.imgURL + item.categories[0].images[0].image
                : null
              : null,
            value: item.name,
            id: item.id,
          },
          {
            title: "Description",
            value: item.description,
          },
          {
            title: "Product Number",
            value: item.product_number,
          },
          {
            title: "Serial Nº",
            value: item.serial_no,
          },
          {
            title: "Category",
            value: item.category
              ? item.category.category_details.name
              : "undefined",
          },
          {
            title: "Model",
            value: item.model,
          },
          {
            title: "Specifications",
            value: item.specifications || "undefined",
          },
          {
            title: "Site",
            value: item.site?.site_details.name || "undefined",
          },
          {
            title: "Client",
            value:
              item.site?.site_details.client?.client_details.name ||
              "undefined",
          },
          {
            title: "Warranty",
            value: item.warranty_date
              ? item.warranty_date + " - " + item.warranty_date_end
              : "not defined yet",
          },
          {
            title: "Last Update",
            value: item.user
              ? item.user.name + " - " + this.timeSince(item.updated_at)
              : "undefined - " + this.timeSince(item.updated_at),
          },
          {
            type: "file",
            title: "Documents",
            value: item.documents.length ? item.documents : "Empty",
          },
        ];
      }
      return [];
    },
    toEdit() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      if (Object.keys(item).length) {
        return [
          {
            type: "image",
            label: "Image",
            value: item.product_images.length
              ? this.imgURL + item.product_images[0].image
              : item.categories.length
              ? item.categories[0].images.length
                ? this.imgURL + item.categories[0].images[0].image
                : null
              : null,
          },
          {
            type: "select",
            label: "Category",
            options: this.api("categories"),
            value: item.categories.length
              ? item.categories.map((el) => {
                  return {
                    id: el.id,
                    name: el.name,
                    image: el.images.length
                      ? this.imgURL + el.images[0].image
                      : null,
                  };
                })[0]
              : undefined,
          },
          {
            type: "text",
            label: "Name",
            value: item.name,
          },
          {
            type: "textarea",
            label: "Description",
            value: item.description,
          },
          {
            type: "text",
            label: "Product Number",
            value: item.product_number,
          },
          {
            type: "text",
            label: "Serial Nº",
            value: item.serial_no,
          },
          {
            type: "text",
            label: "Model",
            value: item.model,
          },
          {
            type: "textarea",
            label: "Specifications",
            value: item.specifications,
          },
          {
            type: "boolean",
            label: "Installed",
            value: item.installed,
            extra: [
              {
                type: "date",
                label: "Warranty Start",
                value: item.warranty_date,
              },
              {
                type: "date",
                label: "Warranty End",
                value: item.warranty_end_date,
              },
              {
                type: "text",
                label: "Utilized by user",
                value: item.product_by,
              },
            ],
          },
          {
            type: "select",
            label: "Status",
            options: [
              { id: 2, name: "Inactive" },
              { id: 1, name: "Active" },
            ],
            value: {
              id: item.state === 1 ? 1 : 2,
              name: item.state === 1 ? "Active" : "Inactive",
            },
          },
        ];
      }
      return [];
    },
  },
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        return [
          {
            template: "image",
            id: item.id,
            data: {
              image: item.product_images.length
                ? this.imgURL +
                  item.product_images[item.product_images.length - 1].image
                : item.categories.length && item.categories[0].images.length
                ? this.imgURL + item.categories[0].images[0].image
                : null,
              title: item.name,
              description: [
                {
                  light: false,
                  text: item.model,
                },
              ],
            },
          },
          {
            template: "normal",
            data: item.serial_no || item.product_number,
          },
          {
            template: "normal",
            data: item.categories.length
              ? item.categories[0].name
              : "undefined",
          },
          {
            template: "normal",
            data: item.site?.site_details.name || "undefined",
          },
          {
            template: "normal",
            data:
              item.site?.site_details.client?.client_details.name ||
              "undefined",
          },
          {
            template: "image",
            data: {
              image: item.user ? this.imgURL + item.user.avatar : null,
              title: item.user ? item.user.name : "undefined",
              description: [
                {
                  light: false,
                  text: this.timeSince(item.updated_at),
                },
              ],
            },
          },
        ];
      });
    },

    async getData(value) {
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      const client_id = this.client ? this.client.id : "";
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response;
        if (!this.filter.length)
          response = await axiosInstance
            .get(
              `product?org_id=${orgData.id}&searchKey=${this.search_key}&clientId=${client_id}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        else
          response = await axiosInstance
            .get(
              `product?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&clientId=${client_id}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = responseData.per_page;
        this.total = responseData.total;
        this.pages = responseData.last_page;
      }
    },

    clientsToShow() {
      const clients = [];
      this.product.sites
        .map((el) => el.clients_list[0])
        .filter((el) => el)
        .map((el) => el.client_details)
        .map((el) => {
          if (!clients.some((cli) => cli.name === el.name)) clients.push(el);
        });
      if (this.client && clients.some((cli) => cli.id === this.client.id))
        return clients.filter((client) => client.id === this.client.id);
      else if (this.client && !clients.some((cli) => cli.id === this.client.id))
        return [];
      return clients;
    },

    filterByItem(data) {
      switch (data[0]) {
        case "categories":
          this.filter = ["categoryId", data[1]];
          break;
        case "sites":
          this.filter = ["siteId", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    async createNew(data) {
      let errors = [];

      if (!data.Name) errors.push("name");
      if (!data["Serial Nº"]) errors.push("serial nº");
      if (!data.Category) errors.push("category");

      const org = JSON.parse(localStorage.getItem("organization"));

      if (errors.length)
        return this.handleAlert(
          `${errors.join(", ")} ${errors.length > 1 ? "are" : "is"} mandatory!`
        );

      let toSend = new FormData();
      for (var e = 0; e < data.Files.length; e++) {
        toSend.append(`documents[${e}][file]`, data.Files[e].og_doc);
        toSend.append(`documents[${e}][name]`, data.Files[e].name);
        toSend.append(`documents[${e}][file_size]`, data.Files[e].og_doc.size);
        toSend.append(`documents[${e}][file_type]`, data.Files[e].extension);
      }
      for (var a = 0; a < data.Images.length; a++) {
        toSend.append(`product_images[${a}]`, data.Images[a].originalFile);
      }
      toSend.append("org_id", org.id);
      toSend.append("name", data.Name || "");
      toSend.append("description", data.Description || "");
      toSend.append("product_number", data["Product Number"] || "");
      toSend.append("serial_no", data["Serial Nº"] || "");
      toSend.append("model", data.Model || "");
      toSend.append("specifications", data.Specifications || "");
      toSend.append("categories[0]", data.Category.id || 0);
      toSend.append("installed", data.Installed ? 1 : 0 || 0);
      if (data["Utilized by user"])
        toSend.append("product_by", data["Utilized by user"] || "");
      if (data["Warranty Start"])
        toSend.append("warranty_date", data["Warranty Start"]);
      if (data["Warranty End"])
        toSend.append("warranty_end_date", data["Warranty End"]);

      try {
        const response = await axiosInstance.post(`product`, toSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response) {
          this.handleAlert(response.data.message);
          this.getData(1);
        }
      } catch (e) {
        this.handleAlert("Something went wrong");
      }
    },

    async handleCreateData(data) {
      console.log(data);
      const org = JSON.parse(localStorage.getItem("organization"));
      let toSend = new FormData();

      toSend.append("org_id", org.id);
      toSend.append("name", data.name);
      toSend.append("categories[0]", data.category.id);
      toSend.append("serial_no", data.serial_no);

      if (data.documents) {
        for (var e = 0; e < data.documents.length; e++) {
          toSend.append(`documents[${e}][file]`, data.documents[e].og_doc);
          toSend.append(`documents[${e}][name]`, data.documents[e].name);
          toSend.append(`documents[${e}][file_size]`, data.documents[e].size);
          toSend.append(
            `documents[${e}][file_type]`,
            data.documents[e].extension
          );
        }
      }

      if (data.image) {
        for (var a = 0; a < data.image.length; a++) {
          toSend.append(`product_images[${a}]`, data.image[a].originalFile);
        }
      }

      if (data.product_no) toSend.append("product_number", data.product_no);
      if (data.model) toSend.append("model", data.model);
      if (data.description) toSend.append("description", data.description);
      if (data.specifications)
        toSend.append("specifications", data.specifications);

      if (data.installed) {
        toSend.append("installed", data.installed.installed ? 1 : 0);
        if (data.installed.warranty_start)
          toSend.append("warranty_date", data.installed.warranty_start);
        if (data.installed.warranty_end)
          toSend.append("warranty_end_date", data.installed.warranty_end);
      } else toSend.append("installed", 0);

      try {
        const response = await axiosInstance.post(`product`, toSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          this.response = [true, true];
          this.handleAlert(response.data.message);
          this.getData(1);
        } else {
          this.response = [true, false];
          this.handleAlert("Something went wrong");
        }
      } catch (e) {
        this.response = [true, false];
        this.handleAlert("Something went wrong");
      }
    },

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleOption(data) {
      this.selItem = data[1];
      switch (data[0]) {
        case "see":
          this.see = true;
          break;
        case "edit":
          this.editItem = true;
          break;
        case "delete":
          this.deleteProduct();
          break;

        default:
          break;
      }
    },

    async editProduct(data) {
      const org = JSON.parse(localStorage.getItem("organization"));
      const item = this.entries.find((el) => el.id === this.selItem) || {};

      let toSend = new FormData();

      toSend.append("org_id", org.id);
      toSend.append("productId", this.selItem);
      toSend.append("name", data.Name || item.name || "");
      toSend.append("description", data.Description || item.description || "");
      toSend.append(
        "product_number",
        data["Product Number"] || item.product_number || ""
      );
      toSend.append("serial_no", data["Serial Nº"] || item.serial_no || "");
      toSend.append("model", data.Model || item.model || "");
      toSend.append(
        "specifications",
        data.Specifications || item.specifications || ""
      );
      if (data.Category) toSend.append(`categories[0]`, data.Category.id);
      for (var i = 0; i < data.Category.length; i++) {
        toSend.append(
          `categories[${i}]`,
          data.Category[i].id || item.category.id || ""
        );
      }
      toSend.append("installed", data.Installed ? 1 : 0 || item.installed);
      if (data["Utilized by user"])
        toSend.append("product_by", data["Utilized by user"]);
      if (data["Warranty Start"])
        toSend.append("warranty_date", data["Warranty Start"]);
      if (data["Warranty End"])
        toSend.append("warranty_end_date", data["Warranty End"]);

      if (data.Images) {
        toSend.append("product_images[0]", data.Images[0].originalFile);
      }

      const response = await axiosInstance.post(`product`, toSend);

      if (response) {
        this.handleAlert(response.data.message);
        this.getData(1);
      }

      this.selItem = 0;
    },

    async deleteProduct() {
      const response = await axiosInstance.delete(`product/${this.selItem}`);

      this.handleAlert(response.data.message);
      this.getData(1);

      this.selItem = 0;

      location.reload();
    },

    handleAction(data) {
      if (data.name === "delete selected") {
        data.data.forEach(async (element) => {
          await axiosInstance.delete(`product/${element}`).finally(() => {
            this.getData(1);
          });
        });
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },
  },
};
</script>